/*
  Base
*/

body {
  font-family: $serif-font-family;
  font-size: 18px;
  color: $body-color;
  line-height: 1.75rem;
}

@media (max-width: $elements-responsive-width) {
  body {
    font-size: 14px;
    line-height: 1.5rem;
  }
  body h1 {
    font-size: 1.5rem;
  }
}

h1{
  font-size: 2rem;
}

h1 a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $sans-serif-font-family;
  color: $dark-gray-color;
}

a {
  text-decoration: underline;
  color: $dark-gray-color;
}

a:hover {
  color: $link-color;
  border-bottom: none;
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

blockquote {
  margin: 10px 20px 10px;
  padding: 0px 15px;
  border-left: 0.25em solid $extra-light-gray-color;
  color: $light-gray-color;
  line-height: 1.5;
}

hr {
  border-top: 1px solid $extra-light-gray-color;
}

/*
  Tables
*/

table {
  border-collapse: collapse;
  margin-bottom: 30px;
  width: 100%;
}

table, th, td {
  border: 1px solid black;
}

th, td {
  padding: 15px;
  text-align: left;
}
